<script setup lang="ts">
import { useFloating, offset, autoUpdate, arrow } from "@floating-ui/vue";
import { toRefs } from "vue";
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    default: "",
  },
  placement: {
    type: String,
    default: "top",
  },
});
const { id, text, placement } = toRefs(props);
const reference = ref(null);
const floating = ref(null);
const floatingArrow = ref(null);
const isShow = ref(false);
const { floatingStyles, middlewareData } = useFloating(reference, floating, {
  middleware: [offset(10), arrow({ element: floatingArrow })],
  whileElementsMounted: autoUpdate,
  placement: placement,
});

// const {x: arrowX, y: arrowY} = middlewareData.arrow;

const opposedSide = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom",
}[placement.value.split("-")[0]];
const style = {
  [opposedSide]: "-4px",
};
// Object.assign(floatingArrow.style, {
//   left: arrowX ? `$${arrowX}px` : "",
//   top: arrowY ? `$${arrowY}px` : "",
//   bottom: "",
//   right: "",
//   [opposedSide]: "-4px",
// });
</script>
<template>
  <button
    ref="reference"
    type="button"
    @mouseenter="isShow = true"
    @mouseleave="isShow = false"
    @focus="isShow = true"
    @blur="isShow = false"
  >
    <slot v-if="$slots.button" name="button" />
    <span v-else>{{ text }}</span>
  </button>
  <div
    v-if="isShow"
    :id="id"
    ref="floating"
    class="absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-center text-sm font-medium text-white shadow-sm transition-opacity duration-300 dark:bg-gray-700"
    :style="floatingStyles"
  >
    <slot v-if="$slots.text" name="text" />
    <span v-else>{{ text }}</span>
    {{ style[0] }}
    <div
      ref="floatingArrow"
      class="absolute h-[8px] w-[8px] rotate-45 bg-gray-900"
      :style="{
        left:
          middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
        top:
          middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
        ...style,
      }"
    />
  </div>
</template>
